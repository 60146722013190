import React from 'react';

const Nubes2 = () => {
    return ( 
		
	<section className="conten-nubes">
		<div className="nubes">
			<img src="/images/nubes.svg" alt="" />
			<div className="planta-1 position-absolute"><img className="img-fluid" src="/images/planta-1.png" alt="" /></div>
			<div className="planta-2 position-absolute"><img className="img-fluid" src="/images/planta-2.png" alt="" /></div>
		</div>
		<div className="ilust-leon position-relative text-center pt-4"><img className="img-fluid" src="/images/leon.png" alt="" /></div>
	</section>
     );
}
 
export default Nubes2;