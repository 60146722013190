import React from 'react';

const Astrologia = () => {
    return ( 
		<section id="astrologia" className="bg-white">
			<div className="container">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center">
						<h2 className="mb-4">El Sol <br />en Astrología </h2>
						<p>El Sol nos ilumina a todos, pero en astrología también nos muestra características específicas de cada uno de nosotros que son únicas e irrepetibles. Esta fuente interminable de energía representa la identidad básica de cada persona, el yo verdadero; el centro o corazón del niño, su fuerza vital. El Sol marca nuestro signo en el zodíaco.</p>
						<p>Para los padres, tíos, abuelos y padrinos curiosos, Mi Astralito, es la oportunidad de conocer a fondo las influencias que los signos astrológicos tienen sobre los pequeños de la familia; todo a través de un cuento personalizado, donde los únicos protagonistas son ellos y los rasgos de su personalidad; sus potencialidades y sus capacidades, según la astrología.</p>
					</div>
				</div>
			</div>
		</section>
     );
}
 
export default Astrologia;