import http from "./httpService";
import { configVariables as configVars } from "../utils/config";


export function validateCode(productId, code){
    return http.get(`${configVars("seleccionesUrl")}/validate-discount-code?productId=${productId}&code=${code}`)
    // return http.post(`${seleccionesUrl}/validate-discount-code`, {productId, code})
}


