import React from "react";
// import OwlCarousel from "react-owl-carousel";
import OwlCarousel from "react-owl-carousel2";
// import '../assets/styles/owl.carousel.min.css';  
// import '../assets/styles/owl.theme.default.min.css?v=1'
import '../assets/styles/owl.css';  
import '../assets/styles/owl-theme.css'

const Signos = () => {

  const options = {
    items: 4,
    nav: true,
    navText: ["<", ">"],
    dots: false,
    rewind: true,
    autoplay: false,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    responsive: {
      0: {
        items: 2
      },
      992: {
        items: 4
      },
    },
  };



  return (
    <section id="carousel">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <div className="owl-theme carousel-signos">
          <OwlCarousel 
          options={options} >

              <div className="item">
                <img src="/images/Astralito_LIbros-2.png" alt="" />
              </div>
              <div className="item">
                <img src="/images/leyendo_500x360.png" alt="" />
              </div>
              <div className="item">
                <img src="/images/Geminis_Book_Mockup.png" alt="" />
              </div>
              <div className="item">
                <img src="/images/Tauro_Book_Mockup.png" alt="" />
              </div>
            </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signos;
