import moment from 'moment'

const getSign = (value) => {
    const dt = moment(value, "YYYY-MM-DD");
    const month = dt.month() + 1
    const day = dt.date()
    // console.log(month, day)
    const signs = [
        {
            start: {month: 3, day: 21},
            end: {month: 4, day: 20},
            name: "Aries"
        },
        {
            start: {month: 4, day: 21},
            end: {month: 5, day: 21},
            name: "Tauro"
        },
        {
            start: {month: 5, day: 22},
            end: {month: 6, day: 21},
            name: "Géminis"
        },
        {
            start: {month: 6, day: 22},
            end: {month: 7, day: 23},
            name: "Cáncer"
        },
        {
            start: {month: 7, day: 23},
            end: {month: 8, day: 24},
            name: "Leo"
        },
        {
            start: {month: 8, day: 24},
            end: {month: 9, day: 23},
            name: "Virgo"
        },
        {
            start: {month: 9, day: 24},
            end: {month: 10, day: 23},
            name: "Libra"
        },
        {
            start: {month: 10, day: 24},
            end: {month: 11, day: 22},
            name: "Escorpio"
        },
        {
            start: {month: 11, day: 23},
            end: {month: 12, day: 22},
            name: "Sagitario"
        },
        {
            start: {month: 12, day: 23},
            end: {month: 1, day: 20},
            name: "Capricornio"
        },
        {
            start: {month: 1, day: 21},
            end: {month: 2, day: 19},
            name: "Acuario"
        },
        {
            start: {month: 2, day: 20},
            end: {month: 3, day: 20},
            name: "Piscis"
        }
    ]
    for(let sign of signs){
        if(month === sign.start.month && day >= sign.start.day)
            return sign
        if(month === sign.end.month && day <= sign.end.day)
            return sign
    }
    return null
}

export default getSign

