import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import swal from "sweetalert";
import {
  getCountries,
  getProvinces,
  getCities,
  getZips,
} from "../services/locationService";
import { validateCode } from "../services/discountCodeService";
import { getShipModes } from "../services/shipModeService";
import { variables as vars } from "../utils/variables";
import { configVariables as configVars } from "../utils/config";
import http from "../services/httpService.js";
import { Link } from "react-router-dom";

class UserForm extends Form {
  state = {
    data: {
      name: "",
      lastname: "",
      email: "",
      email2: "",
      // password: "",
      // password2: "",
      address: "",
      zipId: null,
      provinceId: null,
      cityId: null,
      countryId: vars("countryId"),
      phone: "",
      phonePrefix: "",
      discountCode: "",
      payway: vars("payway"),
      productId: vars("productId"),
      canalId: vars("canalId"),
      mktCodeId: vars("mktCodeId"),
      campaignId: vars("campaignId"),
      shipMode: vars("shipMode"),
      terms: true,
      receives_info: null,
    },
    countries: [],
    cities: [],
    provinces: [],
    zips: [],
    errors: {},
    productPrize: vars("price"),
    deliveryPrize: vars("deliveryPrice"),
    productChanged: false,
    orginalProductId: null,
    originalProductPrize: vars("originalPrice"),
    shipModes: [],
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .max(15)
      .required()
      .error(() => {
        return {
          message: "El nombre es requerido.",
        };
      }),
    lastname: Joi.string()
      .max(15)
      .required()
      .error(() => {
        return {
          message: "El apellido es requerido.",
        };
      }),
    email: Joi.string()
      .max(50)
      .required()
      .error(() => {
        return {
          message: "El email es requerido y debe ser un email válido.",
        };
      }),
    email2: Joi.string().max(50).required(),
    // password: Joi.string()
    //   .max(50)
    //   .required()
    //   .error(() => {
    //     return {
    //       message: "El password es requerido.",
    //     };
    //   }),
    // password2: Joi.string()
    //   .valid(Joi.ref("password"))
    //   .options({
    //     language: {
    //       any: {
    //         allowOnly: "!!Passwords do not match",
    //       },
    //     },
    //   }),
    address: Joi.string()
      .max(150)
      .required()
      .error(() => {
        return {
          message: "La dirección es requerida.",
        };
      }),
    countryId: Joi.any()
      .required()
      .invalid(null)
      .error(() => {
        return {
          message: "El país es requerido.",
        };
      }),
    provinceId: Joi.any()
      .required()
      .invalid(null)
      .error(() => {
        return {
          message: "La provincia es requerida.",
        };
      }),
    cityId: Joi.any()
      .required()
      .invalid(null)
      .error(() => {
        return {
          message: "La ciudad es requerida.",
        };
      }),
    zipId: Joi.any()
      .required()
      .invalid(null)
      .error(() => {
        return {
          message: "El código postal es requerido.",
        };
      }),
    phonePrefix: Joi.string()
      .required()
      .error(() => {
        return {
          message: "El prefijo es requerido.",
        };
      }),
    phone: Joi.string()
      .required()
      .error(() => {
        return {
          message: "El número es requerido.",
        };
      }),
    discountCode: Joi.string().allow(""),
    payway: Joi.string()
      .required()
      .error(() => {
        return {
          message: "El medio de pago es requerido.",
        };
      }),
    campaignId: Joi.number().required(),
    mktCodeId: Joi.any().required(),
    productId: Joi.any().required(),
    canalId: Joi.string().allow(""),
    shipMode: Joi.string()
      .required()
      .error(() => {
        return {
          message: "La forma de entrega es requerida.",
        };
      }),
    terms: Joi.string()
      .valid(true)
      .error(() => {
        return {
          message: "Aceptar las condiciones es requerido.",
        };
      }),
    receives_info: Joi.any().allow(null)
  };

  normalizeAccents = (string) => {
    string = string.replace('&aacute;', 'á')
    string = string.replace('&eacute;', 'é')
    string = string.replace('&iacute;', 'í')
    string = string.replace('&oacute;', 'ó')
    string = string.replace('&uacute;', 'ú')
    return string
  }

  numberWithDots(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  phonePrefixValidate = (value) => {
    if (value === "") {
      return { success: false, msg: "El campo es requerido" };
    }
    if (isNaN(value)) {
      return { success: false, msg: "Solo se aceptan números" };
    }

    if (vars("isInternational")) {
      if (
        !value.startsWith("00") 
      ) {
        return {
          success: false,
          msg: 'El código de área debe empezar con "00"',
        };
      }
      if (value.length !== 4) {
        return {
          success: false,
          msg:
            "El código de país debe tener 4 dígitos. Sin guiones, ni espacios.",
        };
      }
    }else{
      if (
        !value.startsWith("01") &&
        !value.startsWith("02") &&
        !value.startsWith("03")
      ) {
        return {
          success: false,
          msg: 'El código de área debe empezar con "01", "02" o "03".',
        };
      }

      if (value.length < 3) {
        return {
          success: false,
          msg:
            "El código de área debe tener como mínimo 3 dígitos. Sin guiones, ni espacios.",
        };
      }

      if (value.length > 5) {
        return {
          success: false,
          msg:
            "El código de área debe tener como máximo 5 dígitos. Sin guiones, ni espacios.",
        };
      }
    }

    return { success: true };
  };

  phoneValidate = (value) => {
    const phonePrefix = this.state.data.phonePrefix;

    if (phonePrefix === "") {
      return { success: false, msg: "Debes completar primero el prefijo" };
    }

    if (value === "") {
      return { success: false, msg: "El campo es requerido" };
    }

    if (isNaN(value)) {
      return { success: false, msg: "Solo se aceptan números" };
    }

    if (vars("isInternational") === false) {
      if (phonePrefix.length == 3 && value.length != 8) {
        return {
          success: false,
          msg: "El número debe tener 8 dígitos. Sin guiones, ni espacios.",
        };
      } else if (phonePrefix.length == 4 && value.length != 7) {
        return {
          success: false,
          msg: "El número debe tener 7 dígitos. Sin guiones, ni espacios.",
        };
      } else if (phonePrefix.length == 5 && value.length != 6) {
        return {
          success: false,
          msg: "El número debe tener 6 dígitos. Sin guiones, ni espacios.",
        };
      }
    }
    return { success: true };
  };

  handleSelectPayway = (payway, e) => {
    console.log(payway);
    const { data } = this.state;
    data.payway = payway;
    if (payway === "free") this.props.gaEvent("pgratis", "click");
    if (payway === "mp") this.props.gaEvent("pmercado", "click");
    if (payway === "oo") this.props.gaEvent("ppropio", "click");
    if (payway === "fs") this.props.gaEvent("pfs", "click");

    this.setState({ data });
    this.handleSubmit(e);
  };

  handlePhoneChange = (e) => {
    this.handleChange(e);
    const { data, errors } = this.state;
    const input = e.currentTarget;
    // console.log(input.name, input.value);
    let res;
    if (input.name === "phonePrefix") {
      res = this.phonePrefixValidate(input.value);
    } else {
      res = this.phoneValidate(input.value);
    }
    if (!res.success) {
      // swal("Revisa tus datos", res.msg, "error");
      errors[input.name] = res.msg;
      data[input.name] = "";

      this.setState({ data, errors });
    }
  };

  handleEmailConfirmation = async (e) => {
    await this.handleChange(e);
    const { data, errors } = this.state;
    // console.log(input.name, input.value);
    console.log(data.email, data.email2);
    console.log(data.email === data.email2);
    if (data.email != data.email2) {
      // swal("Revisa tus datos", res.msg, "error");
      errors.email2 = "Los emails no coinciden";
    } else {
      if (errors.hasOwnProperty("email2")) delete errors.email2;
    }

    this.setState({ errors });
  };

  handleCodeSubmit = async () => {
    if (!this.state.data.shipMode) {
      swal("Primero tenés que seleccionar la forma de entrega", "", "error");
      const { data } = this.state;
      data.discountCode = "";
      this.setState({ data });
      return;
    }
    const { data } = await validateCode(
      this.state.orginalProductId,
      this.state.data.discountCode
    );
    if (data.success) {
      swal("Código válido", data.msg, "success");
      const { data: stateData, productPrize } = this.state;
      if (data.discountCode.product.prize == 0)
          this.setState({ deliveryPrize: 0 });
        
      stateData["productId"] = data.discountCode.product_id;
        
      this.setState({
        data: stateData,
        productPrize: this.numberWithDots(data.discountCode.product.prize),
        productChanged: true,
      });
    } else {
      swal("Código no válido", data.msg, "error");
    }
  };

  handleLocationChange = async (e) => {
    this.handleChange(e);
    const input = e.currentTarget;

    switch (input.name) {
      case "provinceId":
        await this.populateCities(input.value);
        break;
      case "cityId":
        await this.populateZips(input.value);
        break;
      default:
        break;
    }
  };

  handleShipModeChange = async (e) => {
    // this.handleChange(e);
    const input = e.currentTarget;
    const { data } = this.state;
    data.shipMode = input.value ?? null;
    this.setState({ data });

    this.setOriginalProductValues();
    if (data.shipMode) this.updateOriginalProductId(input.value);
  };

  handleTermsChange = async (e) => {
    const { data } = this.state;
    const target = e.currentTarget;
    const value = target.checked;
    data.terms = value;
    console.log(data);

    this.setState({ data });
  };

  handleNewsChange = async (e) => {
    const { data } = this.state;
    const target = e.currentTarget;
    const value = target.checked;
    data.receives_info = value;
    console.log(data.receives_info)
  }

  updateOriginalProductId = (selectedShipModeId) => {
    const { shipModes, data } = this.state;

    const shipMode = shipModes.find((sM) => sM._id === selectedShipModeId);
    data.productId = shipMode.orginalProductId;

    this.setState({ orginalProductId: shipMode.orginalProductId, data });
  };

  setOriginalProductValues = () => {
    const { data, originalProductPrize } = this.state;
    data.discountCode = "";
    this.setState({ data, productPrize: originalProductPrize });
  };

  doSubmit = async () => {
    console.log("sending...");
    this.props.updateUserData(this.state.data);
  };

  populateCountries = async () => {
    const { data } = await getCountries();
    let countries = data.data.filter(country => country.idTPais !== 30)
    
    this.setState({ countries });
  };

  populateProvinces = async () => {
    const { data } = await getProvinces();
    this.setState({ provinces: data.data });
  };

  populateCities = async (provinceId) => {
    const { data } = await getCities(provinceId);
    this.setState({ cities: data.data });
  };

  populateZips = async (cityId) => {
    const { data } = await getZips(cityId);
    this.setState({ zips: data.data });
  };

  populateShipModes = () => {
    const shipModes = getShipModes();
    this.setState({ shipModes });
  };

  async componentDidMount() {
    this.populateShipModes();
    await this.populateCountries();
    await this.populateProvinces();
    const canalId = this.getQueryStringValue("canalID");
    if (canalId !== "") {
      const { data } = this.state;
      data["canalId"] = canalId;
      this.setState({ data });
    }
  }

  getQueryStringValue(key) {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  }

  render() {
    const {
      countries,
      provinces,
      cities,
      zips,
      errors,
      data,
      productPrize,
      deliveryPrize,
      productChanged,
      shipModes,
    } = this.state;
    return (
      <section id="datos-personales" className=" bg-light pt-4">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <h4> {vars("userFormTitle")} </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  maxLength="15"
                  name="name"
                  placeholder=""
                  className={
                    "form-control" + (errors["name"] ? " is-invalid" : "")
                  }
                />
                {errors["name"] && (
                  <div className="alert alert-danger">{errors["name"]}</div>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="apellido">Apellido</label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  maxLength="15"
                  name="lastname"
                  placeholder=""
                  className={
                    "form-control" + (errors["lastname"] ? " is-invalid" : "")
                  }
                />
                {errors["lastname"] && (
                  <div className="alert alert-danger">{errors["lastname"]}</div>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="mail">Email</label>
                <input
                  onChange={this.handleEmailConfirmation}
                  type="email"
                  maxLength="50"
                  name="email"
                  placeholder=""
                  className={
                    "form-control" + (errors["email"] ? " is-invalid" : "")
                  }
                />
                {errors["email"] && (
                  <div className="alert alert-danger">{errors["email"]}</div>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="mail">{vars("confirmEmail")}</label>
                <input
                  onChange={this.handleEmailConfirmation}
                  type="email"
                  maxLength="50"
                  name="email2"
                  placeholder=""
                  className={
                    "form-control" + (errors["email2"] ? " is-invalid" : "")
                  }
                />
                {errors["email2"] && (
                  <div className="alert alert-danger">{errors["email2"]}</div>
                )}
              </div>
            </div>
            {vars("isInternational") ? (
              // <div className="col-12 col-sm-6">
              //   <div className="form-group">
              //     <label htmlFor="Pais">
              //     País
              //     </label>
              //     <select
              //     name="countryId"
              //     className={
              //       "form-control" +
              //       (errors["countryId"] ? " is-invalid" : "")
              //     }
              //     >
              //     <option value="">{vars("select")}</option>
              //     {countries.map((country) => (
              //       <option
              //         key={country.idTPais}
              //         value={country.idTPais}
              //       >
              //         {country.TP_Pais}
              //       </option>
              //     ))}
              //     </select>
              //   </div>
              // </div>

              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label htmlFor="Pais">País</label>
                  {countries && (
                    <select
                      name="countryId"
                      className={
                        "form-control" +
                        (errors["countryId"] ? " is-invalid" : "")
                      }
                      onChange={this.handleChange}
                    >
                      <option value="">{vars("select")}</option>
                      {countries.map((country) => (
                        <option key={country.idTPais} value={country.idTPais}>
                          {this.normalizeAccents(country.TP_Pais)}
                        </option>
                      ))}
                    </select>
                  )}
                  {/* <label htmlFor="Pais">País</label>
                <input
                  type="text"
                  maxLength="50"
                  name="countryId"
                  placeholder=""
                  onChange={this.handleChange}
                  className={
                    "form-control" + (errors["country"] ? " is-invalid" : "")
                  }
                /> */}
                </div>
              </div>
            ) : null}
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="domicilio">
                  Domicilio ({vars("domicile")})
                </label>
                <input
                  onChange={this.handleChange}
                  type="text"
                  maxLength="150"
                  name="address"
                  placeholder=""
                  className={
                    "form-control" + (errors["address"] ? " is-invalid" : "")
                  }
                />
                {errors["address"] && (
                  <div className="alert alert-danger">{errors["address"]}</div>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="provincia">{vars("province")}</label>
                {vars("isInternational") ? (
                  <input
                    onChange={this.handleChange}
                    type="text"
                    maxLength="150"
                    name="provinceId"
                    placeholder=""
                    className={
                      "form-control" +
                      (errors["provinceId"] ? " is-invalid" : "")
                    }
                  />
                ) : (
                  provinces && (
                    <select
                      name="provinceId"
                      className={
                        "form-control" +
                        (errors["provinceId"] ? " is-invalid" : "")
                      }
                      onChange={this.handleLocationChange}
                    >
                      <option value="">{vars("select")}</option>
                      {provinces.map((province) => (
                        <option
                          key={province.idTProvincia}
                          value={province.idTProvincia}
                        >
                          {province.TP_Provincia}
                        </option>
                      ))}
                    </select>
                  )
                )}
                {errors["provinceId"] && (
                  <div className="alert alert-danger">
                    {errors["provinceId"]}
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                {vars("isInternational") ? (
                  <React.Fragment>
                    <label htmlFor="city">{vars("city")}</label>
                    <input
                      onChange={this.handleChange}
                      type="text"
                      maxLength="150"
                      name="cityId"
                      placeholder=""
                      className={
                        "form-control" + (errors["cityId"] ? " is-invalid" : "")
                      }
                    />
                  </React.Fragment>
                ) : (
                  data.provinceId &&
                  cities && (
                    <React.Fragment>
                      <label htmlFor="city">Localidad</label>
                      <select
                        name="cityId"
                        className={
                          "form-control" +
                          (errors["cityId"] ? " is-invalid" : "")
                        }
                        onChange={this.handleLocationChange}
                      >
                        <option value="">{vars("select")}</option>
                        {cities.map((city) => (
                          <option
                            key={city.idTLocalidad}
                            value={city.idTLocalidad}
                          >
                            {city.TL_Localidad}
                          </option>
                        ))}
                      </select>
                      {errors["cityId"] && (
                        <div className="alert alert-danger">
                          {errors["cityId"]}
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                {vars("isInternational") ? (
                  <React.Fragment>
                    <label htmlFor="zip">Código Postal</label>
                    <input
                      onChange={this.handleChange}
                      type="text"
                      maxLength="150"
                      name="zipId"
                      placeholder=""
                      className={
                        "form-control" + (errors["zipId"] ? " is-invalid" : "")
                      }
                    />
                  </React.Fragment>
                ) : (
                  data.cityId &&
                  zips && (
                    <React.Fragment>
                      <label htmlFor="zip">Código Postal</label>
                      <select
                        name="zipId"
                        className={
                          "form-control" +
                          (errors["zipId"] ? " is-invalid" : "")
                        }
                        onChange={this.handleLocationChange}
                      >
                        <option value="">{vars("select")}</option>
                        {zips.map((zip) => (
                          <option
                            key={zip.idTCodPostal}
                            value={zip.idTCodPostal}
                          >
                            {zip.TC_CodPostal}
                          </option>
                        ))}
                      </select>
                      {errors["zipId"] && (
                        <div className="alert alert-danger">
                          {errors["zipId"]}
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="telefono">Celular</label>
                    <input
                      id={"phone-prefix-"+vars("isInternational")}
                      onChange={this.handlePhoneChange}
                      type="text"
                      name="phonePrefix"
                      placeholder={vars("prefixPlaceholder")}
                      className={
                        "form-control" +
                        (errors["phonePrefix"] ? " is-invalid" : "")
                      }
                    />
                    {errors["phonePrefix"] && (
                      <div className="alert alert-danger">
                        {errors["phonePrefix"]}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="telefono">&nbsp;</label>
                    <input
                      onChange={this.handlePhoneChange}
                      type="text"
                      placeholder={vars("phonePlaceholder")}
                      name="phone"
                      className={
                        "form-control" + (errors["phone"] ? " is-invalid" : "")
                      }
                    />
                    {errors["phone"] && (
                      <div className="alert alert-danger">
                        {errors["phone"]}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {vars("isInternational") ? null : (
              <div className="col-md-6 mb-3">
                <div className="form-group">
                  {shipModes && (
                    <React.Fragment>
                      <label htmlFor="shipmode">
                        Seleccione forma de entrega
                      </label>
                      <select
                        name="shipMode"
                        className={
                          "form-control" +
                          (errors["shipMode"] ? " is-invalid" : "")
                        }
                        onChange={this.handleShipModeChange}
                      >
                        <option value="">{vars("select")}</option>
                        {shipModes.map((shipMode) => (
                          <option key={shipMode._id} value={shipMode._id}>
                            {shipMode.name}
                          </option>
                        ))}
                      </select>
                      {errors["shipMode"] && (
                        <div className="alert alert-danger">
                          {errors["shipMode"]}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>

          {vars("isInternational") ? null : (
            <div className="row">
              <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 text-center cupon">
                <h4>
                  Si tenés un <strong>CUPÓN DE DESCUENTO</strong>, ¡ingresá el
                  código!
                </h4>
                <div className="form-group">
                  <input
                    type="text"
                    onChange={this.handleChange}
                    name="discountCode"
                    value={this.state.data.discountCode}
                    className="form-control"
                    id="cupon"
                  />
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    onClick={this.handleCodeSubmit}
                    className="btn btn-success"
                  >
                    Validar
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 text-center mt-2">
              <div className="form-check d-flex justify-content-between">
                <label style={{fontSize: "13px"}} className="form-check-label">
                  Acepto{" "}
                  <Link target="_blank" to="/terminos-y-condiciones">
                    Términos y condiciones
                  </Link>{" "}
                  de contratación y{" "}
                  <Link target="_blank" to="/proteccion-de-datos-personales">
                    Políticas de Protección de Datos Personales
                  </Link>
                  .
                </label>
                <input
                  type="checkbox"
                  name="terms"
                  className="form-check-input"
                  id="terms"
                  onChange={this.handleTermsChange}
                  defaultChecked
                />
              </div>
              {errors["terms"] && (
                <div className="alert alert-danger">{errors["terms"]}</div>
              )}
            </div>
            <div className="col-xs-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 text-center mt-2">
              <div className="form-check d-flex justify-content-between">
                <label style={{fontSize: "13px"}} className="form-check-label">
                Quiero recibir por email noticias y ofertas especiales de Selecciones y Mi Astralito.
                </label>
                <input
                  type="checkbox"
                  name="receives_info"
                  className="form-check-input"
                  id="receives_info"
                  onChange={this.handleNewsChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div id="precios" className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col text-center mb-4">
                <h2>{vars("orderNow")}</h2>
                <h3
                  className={
                    "card-title pricing-card-title " +
                    (productChanged ? "text-discount" : "")
                  }
                >
                  {!vars("isInternational")
                    ? vars("currency") + this.numberWithDots(productPrize)
                    : vars("currency") + vars("price").toFixed(2)}
                </h3>
                <small
                  className={
                    "text-envios " + (productChanged ? "text-discount" : "")
                  }
                >
                  {this.state.deliveryPrize !== 0 && (this.state.data.shipMode === "DxG" ||
                    this.state.data.shipMode === "ExS") &&
                    `+ ${vars("currency")}${vars("deliveryPrice").toFixed(
                      2
                    )} ${vars("shipping")}`}
                </small>
              </div>
            </div>
            <div className="row">
              {productPrize == 0 && (
                <div className="col-xs-6 col-sm-6 col-lg-4 offset-3 offset-lg-4 mb-3">
                  <div className="card bg-white text-center mb-3">
                    <div className="card-body">
                      <button
                        className="btn btn-primary d-inline-block btn-sm"
                        onClick={(e) => this.handleSelectPayway("free", e)}
                      >
                        Finalizar
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {productPrize != 0 &&
                (vars("isInternational") ? (
                  <React.Fragment>
                    <div className="col-xs-4 col-sm-4 col-lg-4 offset-4 mb-3">
                      <div className="card bg-white text-center mb-3">
                        <div className="card-body d-flex flex-column">
                          <img
                            className="d-block align-self-center mt-3 mb-5 img-fluid"
                            src="/images/fs.png"
                            alt="Pagar con FastSpring"
                          />
                          <button
                            onClick={(e) => this.handleSelectPayway("fs", e)}
                            className="btn btn-primary d-inline-block mb-4 btn-sm align-self-center mt-2"
                          >
                            PAGAR
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="col-xs-6 col-sm-6 col-lg-4 offset-lg-2 mb-3">
                      <div className="card bg-white text-center mb-3">
                        <div className="card-body">
                          <img
                            className="mb-2"
                            src="/images/ahora12.png"
                            alt="Pagar con Ahora 12"
                            onClick={(e) => this.handleSelectPayway("oo", e)}
                          />
                          <div className="mb-4">
                            <a
                              className="btn-cuotas"
                              data-toggle="modal"
                              href="#cuotas-x12"
                            >
                              Ver cuotas
                            </a>
                          </div>
                          <p>
                            Pagá en hasta
                            <br />
                            12 cuotas sin interés
                          </p>
                          <button
                            className="btn btn-primary d-inline-block mb-4 btn-sm"
                            onClick={(e) => this.handleSelectPayway("oo", e)}
                          >
                            PAGAR
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-lg-4 mb-3">
                      <div className="card bg-white text-center mb-3">
                        <div className="card-body">
                          <img
                            className="mb-2"
                            src="/images/mercadopago.png"
                            alt="Pagar con Mercadopago"
                          />
                          <div className="mb-4">
                            <a
                              className="btn-cuotas"
                              target="_blanks"
                              href="https://www.mercadopago.com.ar/cuotas"
                            >
                              Ver cuotas
                            </a>
                          </div>
                          <p>
                            Pagá en
                            <br /> hasta 12 cuotas fijas y en pesos
                          </p>
                          <button
                            onClick={(e) => this.handleSelectPayway("mp", e)}
                            className="btn btn-primary d-inline-block mb-4 btn-sm"
                          >
                            PAGAR
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
            {Object.keys(errors).length != 0 && (
              <div className="row">
                <div className="col">
                  <div className="alert alert-danger">
                    Revisa los datos ingresados
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col text-center">
                {/* <small className="text-muted d-block">
                  * Gastos de envío $399 a todo el país.
                </small> */}
                <small className="text-muted d-block">
                  Recibirás el libro dentro de los próximos 21 días.
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default UserForm;
