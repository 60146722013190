import React from 'react';

const Leon = () => {
    return ( 
    <section id="leon" className="pt-4">
		<div className="container">
			<div className="row">
				<div className="col-xs-12">
					<div className="ei position-absolute">
						<div className=""><img className="titilar" src="/images/estrella-amarilla.svg" alt="" /></div>
						<img className="img-fluid" src="/images/estrellas-izquierda.svg" alt="" />
					</div>
					<div className="ed position-absolute">
						<img className="img-fluid" src="/images/estrellas-derecha.svg" alt="" />
						<div className=""><img className="titilar2" src="/images/estrella-amarilla.svg" alt="" /></div>
					</div>
				</div>
			</div>
		</div>
	</section>
     );
}
 
export default Leon;