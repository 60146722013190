import React from "react";

const Toro = () => {
  return (
    <section className="toro">
      <div className="container-fluid p-0">
        <div className="row p-0 m-0">
          <div className="col-sm-10 offset-sm-1 col-lg-6 offset-lg-0 text-center text-white left-toro">
            <h3>Sol en Tauro</h3>
            <p>
              Los niños Tauro necesitan ir a su propio ritmo para concretar sus
              metas. Pueden llegar a ser muy testarudos. Les gusta la
              estabilidad y les cuestan muchos los cambios.{" "}
            </p>
            <p>
              Para estos pequeños toritos, el contacto con la naturaleza y la
              meditación son buenas prácticas para canalizar la energía de este
              signo.
            </p>
            <div className="ei position-absolute">
              <div className="">
                <img
                  className="titilar"
                  src="/images/estrella-amarilla.svg"
                  alt=""
                />
              </div>
              <img
                className="img-fluid"
                src="/images/estrellas-izquierda.svg"
                alt=""
              />
            </div>
            <div className="ed position-absolute">
              <img
                className="img-fluid"
                src="/images/estrellas-derecha.svg"
                alt=""
              />
              <div className="">
                <img
                  className="titilar2"
                  src="/images/estrella-amarilla.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 p-0 right-toro text-center">
            <img className="img-fluid" src="/images/fondo-toro.jpg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Toro;
