import React from "react";

const PersonalData = () => {
  return (
    <section id="principal" className="text-center bg-white">
      <div className="container position-relative">
        <div className="row">

          <div className="col text-left">
            <h2>Políticas de protección de datos Personales</h2>

            <p>
              One Minetta Media S.A. respeta su derecho a la protección de los
              datos personales, los cuales están amparados por la Ley de
              Protección de Datos Personales N° 25.326. La base de datos es
              responsabilidad de One Minetta Media S.A. con domicilio en
              Holmberg 4156, Planta Baja, Capital Federal y se encuentra
              debidamente registrada conforme con la ley aplicable. Si desea
              conocer los datos de su persona que constan en la base de datos,
              actualizarlos o rectificar errores, o ejercer el derecho de retiro
              o bloqueo total o parcial de su nombre de nuestra base de datos,
              por favor comuníquese por correo a Holmberg 4156, Planta Baja (CP
              C1430DQF), Saavedra, Capital Federal, o por correo electrónico a
              mi.astralito@oneminetta.com, con el asunto: DATOS PERSONALES.
            </p>
            <p>
              Se hace saber que el artículo 27, inciso 3 de la Ley 25.326
              establece que “el titular podrá en cualquier momento solicitar el
              retiro o bloqueo de su nombre de los bancos de datos a los que se
              refiere el presente artículo”. Asimismo, el párrafo tercero del
              artículo 27 del Anexo I del Decreto 1558/01 establece que “en toda
              comunicación con fines de publicidad que se realice por correo,
              teléfono, correo electrónico, Internet u otro medio a distancia a
              conocer, se deberá indicar, en forma expresa y destacada, la
              posibilidad del titular del dato de solicitar el retiro o bloqueo,
              total o parcial, de su nombre de la base de datos. A pedido del
              interesado se deberá informar el nombre del responsable o usuario
              del banco de datos que proveyó la información”. Es posible que, de
              tanto en tanto, pongamos la citada base de datos a disposición de
              organizaciones cuyos productos o servicios, en nuestra opinión,
              podrían resultar de su interés. Si prefiere que no compartamos con
              dichas organizaciones la información referente a su persona, envíe
              un mensaje comunicándonos su decisión a:
              mi.astralito@oneminetta.com o por correo a la dirección antes
              citada.
            </p>
            <p>
              El titular de los datos personales tiene la facultad de ejercer el
              derecho de acceso a los mismos en forma gratuita a intervalos no
              inferiores a seis meses, salvo que se acredite un interés legítimo
              al efecto conforme lo establecido en el artículo 14, inciso 3 de
              la Ley Nº 25.326. La DIRECCION NACIONAL DE PROTECCION DE DATOS
              PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la
              atribución de atender las denuncias y reclamos que se interpongan
              con relación al incumplimiento de las normas sobre protección de
              datos personales.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalData;
