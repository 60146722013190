export const variables = {
    isInternational: false,
    countryId: 11,

    price: 2990,
    originalPrice: 2990,
    deliveryPrice: 590,
    productId: 30,
    campaignId: 96,
    mktCodeId: 336,
    canalId: "2336",
    payway: "",

    ourBooks: "A través de estos cuentos ilustrados, no solo conocerás de una forma didáctica esos rasgos de personalidad que acompañarán a los más pequeños durante toda su vida, sino que además te servirán para conocer y comunicarte mejor con ellos. Podrás potenciar sus fortalezas y trabajar sus debilidades a la vez que cultivás el amor por la lectura.",
    giftTitle: "Regalá Mi Astralito",
    giftText: "Solo tenés que completar los datos para que armemos el perfil solar y el cuento personalizado de tu pequeño.",
    nameSmall: "(Tal como querés que figure en la portada y en el cuento)",
    currency: "$",
    userFormTitle: "Completá tus datos personales",
    confirmEmail: "Confirmá tu email",
    adress: "Domicilio (Donde querés recibir el libro)",
    select: "Seleccione",
    orderNow: "Encargá tu Astralito AHORA MISMO y recibilo en tu domicilio.",
    visitOurWebsite:"Visitá nuestro sitio web: ",
    needHelp:"¿Necesitás ayuda?",
    contactUs: "Contáctate con nosotros",
    domicile: "Donde querés recibir el libro",
    province: "Provincia",
    city: "Ciudad",
    phonePlaceholder: "(número sin 15)",
    prefixPlaceholder: "(prefijo con 0)",
    shipMode: null,
    shipping: "envío a todo el país."
    }