import React from "react";

const Legal = () => {
  return (
    <section id="principal" className="text-center bg-white">
      <div className="container position-relative">
        <div className="row">
          <div className="col text-left">
            <h2>Legales de venta</h2>

            <p>Garantía de Satisfacción</p>
            <p>
              One Minetta Media S.A. es una compañía dedicada a proveer
              productos y servicios de óptima calidad a sus clientes del mundo
              entero. Todos los productos incluidos en sabordecasa.com.ar han
              sido cuidadosamente seleccionados para brindarte excelente calidad
              y el mejor precio. Si al recibir tu pedido no te sintieras
              satisfecho con tu producto, nos comprometemos a devolverte
              inmediatamente tu dinero.
            </p>
            <p>Ley de defensa del consumidor. Artículo 34</p>
            <p>
              El consumidor tiene derecho a revocar la presente operación
              comercial (por adquisición de bienes y/o prestación de servicios)
              durante el plazo de DIEZ (10) días corridos, contados a partir de
              la fecha en que se entregue el bien o se celebre el contrato, lo
              último que ocurra, sin responsabilidad alguna. Esta facultad no
              puede ser dispensada ni renunciada. El consumidor comunicará
              fehacientemente dicha revocación al proveedor y pondrá el bien a
              su disposición. Los gastos de devolución son por cuenta del
              proveedor. Para ejercer el derecho de revocación el consumidor
              deberá poner el bien a disposición del vendedor sin haberlo usado
              y manteniéndolo en el mismo estado en que lo recibió, debiendo
              restituir el proveedor al consumidor los importes recibidos.
            </p>
            <p>
              Gastos de envío total $590 final por cada orden de pedido. Los
              gastos de envío se prorratean en la cantidad de pagos
              seleccionados.
            </p>
            <p>Condiciones de pagos con tarjeta de crédito:</p>
            <p>
              Costo Financiero Total (CFT) 0,00%. Tasa Nominal Anual (TNA)
              0,00%. Válido para tarjetas de crédito emitidas y administradas
              por bancos autorizados en la Argentina. Aplicable para tarjetas de
              crédito que se encuentren vigentes y en estado de cumplimiento.
              ONE MINETTA MEDIA, Holmberg 4156, piso 3, CABA, 30-65642821-6.
              <br />
            </p>
            <p>
              Consultá promociones de Mercado pago en
              <a href="https://www.mercadopago.com.ar/cuotas">
                https://www.mercadopago.com.ar/cuotas
              </a>
            </p>
            <p>
              Consultá términos y condiciones de Mercado Pago en
              <a href="https://www.mercadopago.com.ar/ayuda/terminos-y-condiciones_299">
                https://www.mercadopago.com.ar/ayuda/terminos-y-condiciones_299
              </a>
            </p>
            <p>
              El tiempo máximo de producción es de 15 días corridos. Una vez
              producido se despachará por encomienda certificada a través de
              Correo Argentino.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Legal;
