import React from "react";

const Select = ({ name, label, options, error, showEmptyOption = true,  handleEnter,...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} {...rest} className="form-control" 
          onKeyDown={handleEnter}>
        {showEmptyOption && <option value="">Seleccione</option>}
        {options.map(option => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
