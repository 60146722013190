import React from "react";
import { variables as vars } from "../utils/variables";

const RecorteNena = () => {
  return (
    <section id="recorte-nena" className="pt-4">
      <div className="ilust-recorte d-none d-lg-inline-block">
        <img className="img-fluid" src="/images/recorte-nena2.png" alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-lg-6 offset-lg-0">
            <h2
              className="text-center
					"
            >
              Nuestros libros
            </h2>
            <p>
              {vars("ourBooks")}
            </p>

            <ul className="bullets">
              <li>24 páginas con ilustraciones a color</li>
              <li>Tamaño: 27,2 cm x 21 cm</li>
              <li>Tapa dura</li>
              <li>Personalización y dedicatoria en tapa e interior</li>
              <li>Características generales del perfil solar</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ilust-gallo position-relative">
        <img className="img-fluid" src="/images/gallo.png" alt="" />
      </div>
    </section>
  );
};

export default RecorteNena;
