import React from 'react';
import {Link} from 'react-router-dom'
import tarjetaHormigas from "../assets/images/tarjeta-hormigas.png"

const Thanks = () => {
    return ( 
        <section id="principal" className="text-center bg-white">
		<div className="container position-relative">
			<div className="row">
				<div className="col-sm-10 offset-sm-1 col-lg-6 offset-lg-0 pb-4 pt-4 order-md-2">
					<div className="tarjeta"><img className="d-inline-block img-fluid" src={tarjetaHormigas} alt="" /></div>
				</div>
				<div className="col-sm-10 offset-sm-1 col-lg-5 offset-lg-1 text-lg-left pb-4 order-md-1">
					<h2>¡Recibimos el pedido de tu libro!</h2>
					<h1 className="mb-4">Y estamos validando tu pago. </h1>

					<p> El plazo para su aprobación puede ser de <strong>hasta 48 horas hábiles</strong>. Una vez aprobado, recibirás un correo electrónico con el estado de tu pedido.</p>
					<p>Esta compra, por tu seguridad, pasará por un proceso de validación. En caso de ser necesario, nos pondremos en contacto.</p>

					<p>Para crear un nuevo libro</p>
					<Link className="btn btn-primary d-inline-block mb-4" to="/">Click Aquí</Link>

					<p>Cualquier duda o comentario, escribinos a <a href="mailto:mi.astralito@oneminetta.com" target="_blank">mi.astralito@oneminetta.com</a> indicando tu nombre y teléfono.</p>

					<h2>¡Que lo disfrutes!</h2>
				</div>
			</div>
			
		</div>	
	</section>
     );
}
 
export default Thanks;