import React from "react";
import OwlCarousel from "react-owl-carousel2";
import '../assets/styles/owl.css';  
import '../assets/styles/owl-theme.css'

const Libros = () => {

  const options = {
    items: 4,
    nav: true,
    navText: ["<", ">"],
    dots: false,
    rewind: true,
    autoplay: false,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    responsive: {
      0: {
        items: 2,
        mouseDrag: false,
      },
      992: {
        items: 4
      },
    },
  };


  return (
    <section id="libros">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
            <h2 className="pt-4">Cuentos únicos y personalizados </h2>
            <p className="mb-4">
              Los cuentos personalizados de Mi Astralito son incomparables e
              inimitables. No hay uno igual a otro porque los héroes de cada
              relato reflejan las características únicas e irrepetibles de cada
              signo del zodíaco. Los pequeños protagonistas de estas historias
              serán los propios niños y los rasgos de su propia personalidad.
            </p>

            <p>Ideal para niños de 0 a 5 años.</p>
            <h2 className="mt-4">¡Llevamos la personalización a otro nivel!</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="owl-theme carousel-tapas">
              <OwlCarousel options={options}>
              <div className="item">
                  <img
                    src="/images/tapas/acuario_clara.png"
                    alt="Astralito Clara"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/Aries_Benjamin.png"
                    alt="Aries_Benjamin"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/cancer_tomas.png"
                    alt="cancer_tomas"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/capricornio_trinidad.png"
                    alt="capricornio_trinidad"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/Escorpio_joaquin.png"
                    alt="Escorpio_joaquin"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/geminis_Ana.png"
                    alt="geminis_Ana"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/leo_felipe.png"
                    alt="leo_felipe"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/libra_luz.png"
                    alt="libra_luz"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/piscis_Ulises.png"
                    alt="piscis_Ulises"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/sagitario_Jaime.png"
                    alt="sagitario_Jaime"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/tauro_Antonia.png"
                    alt="tauro_Antonia"
                  />
                </div>
                <div className="item">
                  <img
                    src="/images/tapas/virgo_fede.png"
                    alt="virgo_fede"
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
          <div className="col-md-12 text-center mb-4">
            <a className="btn btn-primary" href="#compra">
              ¡Quiero!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Libros;
