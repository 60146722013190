import React, { useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home";
import Thanks from "./components/thanks";
import Footer from "./components/footer";
import Header from "./components/header";
import Nubes from "./components/nubes";
import Terms from "./components/terms";
import Legal from "./components/legal";
import PersonalData from "./components/personalData";
import { useEffect } from "react";
import { getLocation } from "./services/locationService";
import { variables as vars } from "./utils/variables";

function App() {
  const [shouldRedirect, setShouldRedirect] = useState(true);

  useEffect(() => {
    checkLocation();
  }, []);

  const getQueryStringValue = (key) => {
    return decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  };

  const checkLocation = async () => {
    const { data } = await getLocation();
    const country = data.country;
    if (!vars("isInternational") && country === "AR") {
      return window.location.assign(
        "https://plickme.com/p/mi-astralito-un-cuento-para-cada-signo-del-zodiaco/595010e1-a90f-4f39-8041-f26becb556d4"
      );
    }
    if (!vars("isInternational") && country !== "AR") {
      let redirectUrl = "/exterior";
      const canalId = getQueryStringValue("canalID");
      if (canalId !== "") redirectUrl += "?canalID=" + canalId + "e";
      return window.location.assign(redirectUrl);
    }
    setShouldRedirect(false);
  };

  return (
    !shouldRedirect && (
      <React.Fragment>
        <Header />
        <Nubes />
        <Switch>
          <Route path="/gracias" component={Thanks} />
          <Route path="/terminos-y-condiciones" component={Terms} />
          <Route
            path="/proteccion-de-datos-personales"
            component={PersonalData}
          />
          <Route path="/legales-de-venta" component={Legal} />
          <Route path="/exterior" component={Home} />
          <Route path="/" component={Home} />

          {/* <Redirect from="/" exact to="/" /> */}
          <Redirect to="/not-found" />
        </Switch>
        <Footer />
      </React.Fragment>
    )
  );
}

export default App;
