import http from "./httpService";
import { configVariables as configVars } from "../utils/config";


export function checkout(body){
    return http.get(generateUrl(`${configVars("apiUrl")}/checkout`, body))
}

// export function confirmPurchase(body){
//   return http.get(generateUrl(`${configVars("apiUrl")}/confirm-purchase`, body))
// }

const generateUrl = (url, params) => {
      
    url += "?"
    
    if (typeof params !== "object") return "";
    
    for (let [key, value] of Object.entries(params)) {
      if(value != "")
        url += `${key}=${encodeURIComponent(value)}&`
    }
  
    return url.substr(0, url.length - 1)
  
  }

