import { variables as configVarsLocal } from './configLocal';
import { variables as configVarsQa } from './configQa';
import { variables as configVarsProd } from './configProd'

export const configVariables = (word) => {
    const hostname = window.location.host
    switch (hostname) {
        case "miastralito.oneminetta-quality.com":
            return configVarsQa[word]
        case 'www.miastralito.com.ar':
        case 'www.miastralito.com':
        case 'www.miastralito.com.ar':
            return configVarsProd[word]
        default:
            return configVarsProd[word]
    } 
}