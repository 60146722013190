import React from "react";

const Modal = () => {
  return (
    <div
      className="modal fade"
      id="cuotas-x12"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Mi Astralito + ENVÍO{" "}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Precio Regular</th>
                    <th>VISA - MASTERCARD - ARGENCARD</th>
                    <th>RESTO DE TARJETAS DE CRÉDITO</th>
                    <th>TARJETAS DE DÉBITO</th>
                  </tr>
                  <tr>
                    <td>1 PAGO</td>
                    <td> $ 2,889.00  </td>
                    <td> $ 2,889.00 </td>
                    <td> $ 2,889.00 </td>
                  </tr>
                  <tr>
                    <td>3 cuotas sin interés</td>
                    <td> $  963.00  </td>
                    <td> $  963.00  </td>
                    <td> -</td>
                  </tr>
                  <tr>
                    <td>6 cuotas sin interés</td>
                    <td> $  481.50  </td>
                    <td> -</td>
                    <td> -</td>
                  </tr>
                  <tr>
                    <td>12 cuotas sin interés</td>
                    <td> $  240.75  </td>
                    <td> -</td>
                    <td> -</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="info-legales">
              Costo Financiero Total (CFT) 0,00%. Tasa Nominal Anual (TNA)
              0,00%. Válido para tarjetas de crédito emitidas y administradas
              por bancos autorizados en la Argentina. Aplicable para tarjetas de
              crédito que se encuentren vigentes y en estado de cumplimiento.
              ONE MINETTA MEDIA, Holmberg 4156, piso 3, CABA, 30-65642821-6.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    // <div className="modal fade" id="cuotas-x12" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    // 	<div className="modal-dialog modal-lg">
    //     	<div className="modal-content">
    //       		<div className="modal-header">
    //         		<h5 className="modal-title" id="exampleModalLabel">Mi Astralito</h5>
    //         		<button type="button" className="close" data-dismiss="modal" aria-label="Close">
    //           			<span aria-hidden="true">&times;</span>
    //         		</button>
    //       		</div>
    //       		<div className="modal-body">
    //       			<img className="img-fluid mb-3" src="/images/cuotas-x12.JPG" alt="Pagos en 12 Cuotas" />
    //         		<p className="info-legales">Costo Financiero Total (CFT) 0,00%. Tasa Nominal Anual (TNA) 0,00%. Válido para tarjetas de crédito emitidas y administradas por bancos autorizados en la Argentina. Aplicable para tarjetas de crédito que se encuentren vigentes y en estado de cumplimiento. ONE MINETTA MEDIA, Holmberg 4156, piso 3, CABA, 30-65642821-6.</p>
    //       		</div>
    //       		<div className="modal-footer">
    //         		<button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cerrar</button>
    //       		</div>
    //     	</div>
    //   	</div>
    // </div>
  );
};

export default Modal;
