import React from "react";

const Principal = (props) => {
  const {gaEvent} = props
  return (
    <section id="principal" className="text-center bg-white">
      <div className="container position-relative">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-lg-6 offset-lg-0 pb-4 pt-4 order-md-2">
            <div className="libro">
              <img
                className="d-inline-block"
                src="/images/Astralito_LIbros-2.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-sm-10 offset-sm-1 col-lg-5 offset-lg-1 text-lg-left pb-4 order-md-1">
            <h2>¡Un regalo para toda la vida!</h2>
            <h1 className="mb-4">
              Mi Astralito es la única colección de cuentos 100% personalizados
              en la que los protagonistas de las historias son tus hijos,
              nietos, sobrinos o ahijados… ¡los pequeños de la familia! y las
              características únicas e irrepetibles de su signo zodiacal.
            </h1>
            <a className="btn btn-primary d-inline-block mb-4" onClick={() => gaEvent("quiero", "click")} href="#compra">
              ¡Quiero!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Principal;
