import React, { Component } from "react";
import Joi from "joi-browser";
// import Input from "./input";
// import InputNumber from './inputNumber'
// import Textarea from "./textarea";
import Select from "./select";
// import Checkbox from "./checkbox";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
      // console.log(name,value)
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();
  //  console.log("here")
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    if(input.type ==="checkbox")
      input.value = input.checked
    data[input.name] = input.value;
    // console.log(input.name, input.value)
    this.setState({ data, errors });
  };

  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

//   renderButton(label) {
//     return (
//       <button className="btn btn-primary">
//         {label}
//       </button>
//     );
//   }

  renderSelect(name, label, options, showEmptyOption = true) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        handleEnter={this.handleEnter}
        showEmptyOption={showEmptyOption}
      />
    );
  }

//   renderInput(name, label, type = "text") {
//     const { data, errors } = this.state;

//     // console.log(name, data[name]) 

//     return (
//       <Input
//         type={type}
//         name={name}
//         value={data[name]}
//         label={label}
//         onChange={this.handleChange}
//         error={errors[name]}
//         handleEnter={this.handleEnter}
//       />
//     );
//   }

//   renderInputNumber(name, label, type = "number", min = 0, max = 0, step=0) {
//     const { data, errors } = this.state;

//     // console.log(name, data[name]) 

//     return (
//       <InputNumber
//         type={type}
//         name={name}
//         value={data[name]}
//         label={label}
//         onChange={this.handleChange}
//         error={errors[name]}
//         min={min}
//         max={max}
//         step={step}
//         handleEnter={this.handleEnter}
//       />
//     );
//   }

//   renderTextarea(name, label) {
//     const { data, errors } = this.state;

//     // console.log(name, data[name]) 

//     return (
//       <Textarea
//         name={name}
//         value={data[name]}
//         label={label}
//         onChange={this.handleChange}
//         error={errors[name]}
//         handleEnter={this.handleEnter}
//       />
//     );
//   }

//   renderCheckbox(name, label, type = "text") {
//     const { data, errors } = this.state;

//     // console.log(name, data[name]) 

//     return (
//       <Checkbox
//         type={type}
//         name={name}
//         value={data[name]}
//         label={label}
//         onChange={this.handleChange}
//         error={errors[name]}
//       />
//     );
//   }
}

export default Form;
