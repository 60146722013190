import React from "react";
import {Link} from 'react-router-dom';
import { variables as vars } from "../utils/variables";

const Footer = () => {
  return (
    <footer className="bg-gradient">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mt-4 text-center text-md-left">
            {/* <p>
              <strong> { vars("visitOurWebsite") } </strong>
              <br />
              <a
                className="sitioweb"
                target="_blank"
                href="https://miastralito.com/"
              >
                miastralito.com
              </a>
            </p> */}
            <p>
              <strong>{vars("needHelp")}</strong>
              <br />
              <a href="https://wa.me/5491127612538" target="_blank">
                {vars("contactUs")} <i className="fab fa-whatsapp"></i>
              </a>
            </p>
          </div>

          <div className="col-md-7  text-center text-md-right mt-4 legales">
            <strong>Mi Astralito en redes sociales: </strong>
            <br />
            <a
              className="d-inline-block mr-1"
              href="https://www.facebook.com/miastralito.libros"
              target="_blank"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              className="d-inline-block"
              href="https://www.instagram.com/mi_astralito/"
              target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col text-center mt-4">
            <p className="mb-1">
              <Link to="/legales-de-venta" target="_blank"> Legales de Venta |</Link>
              <Link to="/proteccion-de-datos-personales"  target="_blank">
                Política de Protección de datos personales |
              </Link>
              <Link to="/terminos-y-condiciones" target="_blank">Términos y Condiciones</Link>
            </p>
            <small>Copyright 2020-2020 One Minetta Media SA</small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
