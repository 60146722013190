import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header>
      <div className="container-fluid">
        <div className="text-left text-sm-center conten-logo">
          <Link to="/">
            <img className="" src="/images/mi-astralito.png" />
          </Link>
        </div>

        <nav>
          <ul>
            <li>
              <a className="nav-link" href="#libros">
                ¿Qué es?
              </a>
            </li>
            <li>
              <a className="nav-link" href="#astrologia">
                Perfil solar
              </a>
            </li>
            <li>
              <a className="nav-link" href="#recorte-nena">
                Nuestros libros
              </a>
            </li>
            <li>
              <a className="nav-link" href="#compra">
                Regalar
              </a>
            </li>
          </ul>
          <div className="button">
            <a className="btn-open" onClick={() => setShowMenu(true)}></a>
          </div>
        </nav>
        {showMenu && (
          <div className="overlay" onClick={() => setShowMenu(false)}>
            <div className="wrap">
              <ul className="wrap-nav">
                <li>
                  <a href="#libros">¿Qué es?</a>
                </li>
                <li>
                  <a href="#astrologia">Perfil solar</a>
                </li>
                <li>
                  <a href="#recorte-nena">Nuestros libros</a>
                </li>
                <li>
                  <a href="#compra">Regalar</a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="ei position-absolute">
        <div className="">
          <img className="titilar" src="/images/estrella-amarilla.svg" alt="" />
        </div>
        <img
          className="img-fluid"
          src="/images/estrellas-izquierda.svg"
          alt=""
        />
      </div>
      <div className="ed position-absolute">
        <img className="img-fluid" src="/images/estrellas-derecha.svg" alt="" />
        <img
          className="titilar2 e1 position-absolute"
          src="/images/estrella-amarilla.svg"
          alt=""
        />
        <img
          className="titilar e2 position-absolute"
          src="/images/estrella-amarilla.svg"
          alt=""
        />
      </div>
      <div className="contenedor-fugaz d-none">
        <img
          id="fugaz"
          className="fugaz p-1"
          src="/images/estrella-fugaz.svg"
          alt=""
        />
      </div>
    </header>
  );
};

export default Header;
