import React from "react";

const Nubes = () => {
  return (
    <section className="conten-nubes">
      <div className="nubes">
        <img src="/images/nubes.svg" alt="" />
      </div>
    </section>
  );
};

export default Nubes;
