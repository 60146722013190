import React from "react";

const PersonalData = () => {
  return (
    <section id="principal" className="text-center bg-white">
      <div className="container position-relative">
        <div className="row">
          <div className="col text-left">
            <h2>Términos y Condiciones</h2>

            <ol>
              <li>
                <strong>
                  ACEPTACIÓN DE LAS CONDICIONES GENERALES DE CONTRATACIÓN
                </strong>
              </li>
            </ol>
            <p>
              Estas son las Condiciones Generales de Registración que regulan la
              relación contractual por la que los usuarios de Internet acceden a
              los contenidos y servicios que One Minetta Media S.A. (en adelante
              One Minetta pone a su disposición a través del sitio
              http://miastralito.com, en adelante miastralito.com.
            </p>
            <p>
              El usuario al dar al botón "Leí y acepto estas condiciones" en el
              proceso de acceso a los contenidos miastralito.com, admite haber
              leído las presentes Condiciones del Registro y Contratación y
              expresa su adhesión plena y sin reservas a todas y cada una de
              ellas.
            </p>
            <p>
              Asimismo, debido a que ciertos servicios y contenidos ofrecidos
              por One Minetta pueden contener normas específicas que reglamentan
              y complementan a las presentes Condiciones Generales de
              Registración, se recomienda a los usuarios tomar conocimiento
              específico de ellas. Además de las presentes Condiciones Generales
              de Registro y Contratación y las normas que los complementan que
              regulan la relación jurídica que se establece entre los usuarios y
              One Minetta, existen unas condiciones de uso del sitio
              miastralito.com detalladas en el Reglamento de participaciones de
              miastralito.com ( ) que todo usuario debe respetar.
            </p>
            <p>
              El usuario que no acepte, no este de acuerdo, o incumpla las
              disposiciones fijadas por One Minetta en estas Condiciones
              Generales de Registración, no contara con autorización para el uso
              de los servicios y contenidos que existen o puedan existir en el
              sitio, debiendo abstenerse de ingresar nuevamente al sitio y/o
              utilizar cualquier servicio del mismo. Consecuentemente el acceso,
              permanencia, y utilización de los servicios y contenidos por parte
              de un usuario implicará la lisa y llana aceptación por parte del
              usuario, de todas y cada una de las disposiciones dictadas por One
              Minetta
            </p>
            <ol>
              <li>
                <strong>OBJETO</strong>
              </li>
            </ol>
            <p>
              El objeto de las presentes Condiciones Generales de Registración y
              Contratación es la regulación de la relación entre los usuarios de
              Internet que accedan al sitio miastralito.com y utilicen los
              servicios y/o adquieran productos a través de miastralito.com.
            </p>
            <ol>
              <li>
                <strong>De la registración de los usuarios</strong>
                <br />
                3.a. Información personal de los Miembros
              </li>
            </ol>
            <p>
              Para el ingreso, permanencia en miastralito.com, así como para la
              utilización de los servicios y/o contenidos incluidos en el mismo,
              basta la sola aceptación de los Condiciones Generales de
              Registración y particulares dispuestas o que pueda disponer One
              Minetta. Sin embargo para la utilización de algunos servicios, o
              el acceso a determinados contenidos podrá establecerse como
              requisito, el previo registro del usuario. Dicho registro tendrá
              por finalidad establecer la identidad e información de contacto
              del miembro del usuario.
            </p>
            <p>
              A los fines de su registración, la información suministrada por
              los usuarios deberá ser fidedigna, y tendrá el carácter de
              declaración jurada. Cuando la información suministrada no atienda
              a las circunstancias reales de quien brinda la información, se
              considerará a tal usuario incurso en incumplimiento de estas
              Condiciones Generales de Registración, siendo responsable por
              todos los perjuicios sufridos por One Minetta o terceros, como
              consecuencia de tal falta de veracidad. Podrá además tal
              circunstancia ser usada en su contra como hecho constitutivo de
              mala fe contractual.
            </p>
            <p>
              Los servicios o contenidos sujetos a registración han sido
              concebidos para el uso personal del usuario requirente, por tanto
              el nombre de usuario y la contraseña de acceso brindados por One
              Minetta sólo podrán ser usados por este, estando prohibida su
              utilización por otra persona distinta al mismo. El usuario
              registrado asumirá la obligación de guarda y custodia de su nombre
              de usuario y clave de acceso, debiendo informar inmediatamente a
              la administración de One Minetta cuando los mismos hubieren
              perdido su estado de confidencialidad.
            </p>
            <p>
              Será también responsabilidad de cada usuario mantener actualizada
              su información personal asentada en el registro, debiendo
              comunicar a la Administración de One Minetta toda vez que se
              produzcan cambios en relación a la misma.
            </p>
            <p>
              En todos los casos, y de acuerdo con las políticas de privacidad
              sostenido por One Minetta, toda la información de carácter
              personal suministrada por los usuarios será objeto de adecuado
              tratamiento y preservación, en resguardo de la privacidad de los
              mismos.
            </p>
            <p>
              En tanto los servicios brindados por One Minetta, se encuentran
              dirigidos entre otros fines, a facilitar y promover el contacto e
              interacción entre usuarios bajo la dinámica de una red social; los
              usuarios expresamente renuncian a cualquier expectativa de
              privacidad, con respecto a la información y elementos incorporados
              por éstos al sitio y sobre los cuales dieron consentimiento para
              ser mostrados. Los usuarios que no deseen que su información o
              fotografías puedan eventualmente ser visualizadas por otros
              usuarios o al público en general, no deberán proceder a
              registrarse en One Minetta.
            </p>
            <p>3.b. Capacidad para ser usuario.</p>
            <p>
              Sólo podrán ser usuarios, quienes a tenor de la legislación
              vigente, puedan válidamente emitir su consentimiento para la
              celebración de contratos. Quienes a tenor de la legislación
              vigente no posean tal capacidad para acceder u obligarse
              válidamente a los términos de los servicios y contenidos del
              sitio, deberán obtener autorización previa de sus representantes
              legales, quienes serán considerados responsables de todos los
              actos realizados por los incapaces a su cargo.
            </p>
            <p>
              Cuando se trate de falta de capacidad por minoría de edad, la
              responsabilidad en la determinación de los servicios y contenidos
              a los que acceden los menores de edad corresponde a los mayores a
              cuyo cargo se encuentren. En ese orden está prohibido el acceso al
              sitio por parte de menores de 18 años que no contaren con la
              expresa autorización de sus padres, tutores o representantes
              legales.
            </p>
            <ol>
              <li>
                <strong>CONTENIDOS DE </strong>
                <a href="http://www.sabor/">
                  <strong>WWW.SABOR</strong>
                </a>
                <strong>DECASA.COM.AR</strong>
              </li>
            </ol>
            <p>
              Los contenidos, servicios y productos a los que se refiere el
              objeto del presente contrato son todos los que se incluyen en
              miastralito.com bajo el dominio miastralito.com a los que el
              usuario accede directamente desde dicha dirección.
            </p>
            <p>
              Los contenidos a los que el usuario accede mediante enlace desde
              miastralito.com se regirán por sus propias condiciones de registro
              y contratación.
            </p>
            <p>
              El acceso a cada contenido, servicio o adquisición de productos
              requerirá una contraprestación económica por parte del usuario en
              los términos establecidos en las propias condiciones de registro y
              contratación de miastralito.com o de cada sitio enlazado a
              miastralito.com.
            </p>
            <ol>
              <li>
                <strong>PROPIEDAD INTELECTUAL</strong>
              </li>
            </ol>
            <p>5.a. Material titularidad de One Minetta.</p>
            <p>
              Por regla general la titularidad de todo el material existente
              dentro del Sitio corresponde a One Minetta, únicamente podrá
              considerarse que tal material no es de su propiedad cuando el
              mismo hubiere sido provisto por un tercero, o cuando el mismo
              pertenezca un autor ajeno a One Minetta, esto último sin perjuicio
              que en cada uno de los casos One Minetta cuente con las
              respectivas licencias o autorizaciones de uso sobre dicho
              material. A título meramente enunciativo, se entenderán incluidos
              las imágenes, fotografías, diseños, gráficos, sonidos, textos,
              mensajes, documentos, compilaciones de datos, marcas, nombres,
              títulos, designaciones, signos distintivos, y todo otro material
              accesible a través del sitio.
            </p>
            <p>
              One Minetta se reserva todos los derechos sobre el mencionado
              material, no cede ni transfiere a favor del usuario ningún derecho
              sobre su propiedad intelectual o la de terceros. En consecuencia,
              su reproducción, exhibición, distribución, y/o modificación deberá
              ser expresamente autorizada por parte de One Minetta,
              considerándose en caso contrario, una actividad ilícita violatoria
              de los derechos de propiedad intelectual de One Minetta.
            </p>
            <p>
              Los usuarios del Sitio sólo contarán con autorización para la
              utilización del material propiedad de One Minetta, cuando las
              finalidades de tal utilización sean aquellas específicamente
              previstas por One Minetta.
            </p>
            <p>5.b. Contenido suministrado por los usuarios</p>
            <p>
              Los usuarios no podrán en ningún caso, mediante la utilización de
              los servicios o contenidos que One Minetta pone a su disposición,
              llevar adelante acciones que impliquen la violación a los derechos
              que integran la propiedad intelectual de terceras personas. Los
              usuarios sólo podrán remitir a One Minetta u otros usuarios,
              elementos y/o materiales sobre los cuales posean todos los
              derechos relativos a su propiedad. En caso que el usuario que
              envíe el material no sea su propietario, deberá contar con expresa
              autorización del titular de los mismos, concedida a esos efectos.
              La fijación, exhibición, difusión y reproducción de material
              protegido por el derecho de autor, por una patente de invención,
              por un modelo de utilidad, por secreto comercial, etc., sin que
              para ello medie expresa autorización del titular de los derechos,
              será considerado uso prohibido, y en todos los supuestos el
              usuario remitente será responsable directo ante One Minetta y los
              terceros afectados, por la infracción a tales derechos, incluidos
              los daños y perjuicios que pudiere experimentar su titular,
              manteniendo a One Minetta indemne de cualquier menoscabo que
              hubiere sido producido como consecuencia de los reclamos
              interpuestos por el titular de los derechos.
            </p>
            <p>5.b.1 Sobre las fotografías</p>
            <p>
              Todo usuario al momento de enviar una fotografía a One Minetta
              deberá tener presente: Las fotografías en tanto obras humanas se
              encuentran alcanzadas por los derechos de propiedad intelectual,
              en este sentido las leyes nacionales y los tratados
              internacionales sobre la materia, reconocen y otorgan a su autor
              todos los derechos autorales sobre las mismas (ciertos países
              efectúan similar reconocimiento a través del Copyright). De esta
              forma toda fotografía, sin importar el medio utilizado para su
              captura, tendrá un autor, y éste será la única persona legitimada
              para disponer sobre la misma. Existen ciertas fotografías que, ya
              sea por el tiempo transcurrido desde su publicación o porque su
              autor ha decidido compartirla libremente permitiendo su
              reproducción, se encuentran en el dominio público, pudiendo ser
              utilizadas libremente por cualquier persona. Sin embargo el hecho
              que se desconozca, o no pueda determinarse con exactitud quien es
              el autor de una fotografía, no implica que la fotografía se
              encuentre en el dominio público. Por otro lado, la circunstancia
              que una fotografía constituya el retrato de una persona, agrega
              una nueva consideración a tenerse presente además de los derechos
              de autor, la publicación de este tipo de fotografías conlleva el
              consentimiento de la persona que ha sido retratada, en tanto se
              vincula directamente con su derecho a la propia imagen. De lo
              dicho el usuario ha de tener presente en pos de evitar incurrir en
              una conducta ilícita, que derive en reclamos y acciones de
              terceros en su contra, lo siguiente: Solo deben enviarse
              fotografías de las cuales el usuario resulte autor, o en su
              defecto cuente al menos con la autorización del verdadero autor
              para utilizarlas con tal destino. Además de lo establecido
              precedentemente, cuando la fotografía sea un retrato personal,
              deberá corresponder al remitente de la misma, o en su caso, el
              usuario remitente deberá contar con la autorización de la/s
              persona/s retratadas. El usuario que obrare en infracción a
              derechos autorales o el Copyright de terceros, o cuando remitiere
              un retrato que no corresponda a su persona (salvo que probase
              contar con la autorización de la persona retratada), será
              exclusivo responsable de todo los daños y prejuicios que a
              consecuencia de ello One Minetta o cualquier tercero pudiere
              experimentar tanto en forma directa como indirecta.
            </p>
            <p>5.b.2 Reserva de aceptación</p>
            <p>
              One Minetta a su exclusivo criterio, determinará si las
              fotografías enviadas son aceptables o no para su publicación en el
              Sitio. Tal consideración responderá a criterios propios del
              personal de One Minetta y NO estará resumida a consideraciones
              sobre la autenticidad de la imagen o su idoneidad. Las fotografías
              publicadas en el Sitio podrán en cualquier momento y sin previo
              aviso, ser removidas, a solo criterio de One Minetta.
            </p>
            <p>5.c Autorización de uso en beneficio de One Minetta</p>
            <p>
              El usuario concede a One Minetta autorización de uso en relación a
              todo el material que el usuario incorporare en el sitio de One
              Minetta, habilitando a esta última para efectuar la fijación,
              exhibición, comunicación pública, reproducción, transformación,
              adaptación, distribución, y cualesquiera otras actividades sobre
              dicho material, exclusivamente a través el sitio miastralito.com.
            </p>
            <p>5.d. Contenido de terceros</p>
            <p>
              En uso de los servicios ofrecidos en el Sitio, el usuario puede
              tener acceso a contenidos provistos por otros usuarios o terceros.
              One Minetta realiza sus mejores esfuerzos para controlar el
              material que le es suministrado, sin embargo, el usuario acepta
              que eventualmente podrá ser expuesto a contenido de terceros que
              sea falso, ofensivo, indecente o de otra manera inaceptable. Bajo
              ninguna circunstancia podrá responsabilizar a One Minetta por tal
              circunstancia.
            </p>
            <p>
              One Minetta no extiende ningún tipo de garantía sobre la licitud,
              fiabilidad y/o legitimidad del material provisto por terceros,
              será por exclusiva cuenta y riesgo del usuario el acceso y
              utilización de los elementos, productos, o servicios suministrados
              por terceros ajenos a One Minetta.
            </p>
            <ol>
              <li>
                <strong>Libre acceso a los Servicios</strong>
              </li>
            </ol>
            <p>
              Sin perjuicio de la obligación de registro cuando ello fuere
              necesario, y del cumplimiento de los términos de cada reglamento
              en particular, todos los servicios y contenidos ofrecidos en el
              sitio son libremente accesibles por parte de los miembros de los
              usuarios. La libre accesibilidad incluye la gratuidad de los
              servicios, que no estarán sujetos al pago de ningún arancel o
              retribución hacia One Minetta Tal gratuidad no es de aplicación
              sobre los servicios de terceros brindados a través del sitio que
              podrán no ser gratuitos, y en igual sentido aquellos servicios o
              contenidos actuales o futuros, sobre los que One Minetta decida
              establecer un canon para su uso por parte de los usuarios.
            </p>
            <ol>
              <li>
                <strong>
                  Utilización de los servicios y contenidos brindados por el
                  sitio
                </strong>
              </li>
            </ol>
            <p>
              Los usuarios deberán utilizar los servicios, y acceder a los
              contenidos del sitio de conformidad con las disposiciones
              establecidas en estas Condiciones Generales de Registración, con
              las que surjan de los reglamentos de cada servicio, con el
              ordenamiento jurídico considerado en su conjunto, y según las
              pautas de conducta impuestas por la moral y las buenas costumbres.
            </p>
            <p>
              Los servicios y contenidos existentes en el sitio, han sido
              concebidos principalmente como una herramienta de comunicación no
              profesional, integración social, fomento del conocimiento humano,
              y como un medio de esparcimiento.
            </p>
            <p>7.a. USO PROHIBIDO de los servicios o contenidos</p>
            <p>
              Cualquier uso de los servicios o contenidos que tenga por objeto,
              lesionar los derechos de terceros, contravenir el orden jurídico o
              constituya una práctica ofensiva al pudor público, se reputará
              como USO PROHIBIDO de los servicios o contenidos, en tanto
              transgrede los fines para los que fue puesto a disposición de los
              usuarios.
            </p>
            <p>
              Se considerará como USO PROHIBIDO, entre otros, la fijación,
              propagación, y reproducción de información, expresiones, mensajes,
              fotografías, imágenes, archivos informáticos, vínculos a páginas
              web, y todo otro elemento, actual o que se desarrolle en el
              futuro, que:
            </p>
            <p>
              * Resulte ofensivo para los derechos personalísimos de los
              individuos, con especial referencia al derecho al honor, a la
              dignidad, a la intimidad, a no ser objeto de tratos
              discriminatorios, a la salud, a la imagen, y a la libre expresión
              de las ideas; con absoluta independencia del cuerpo legal donde
              tales derechos adquieran reconocimiento.
              <br />
              * Fomente discriminaciones u odios en razón de raza, origen, sexo,
              religión, inclinaciones políticas, condición social, condición
              económica, etc.
              <br />
              * Tenga por objeto vulnerar la seguridad, y o normal
              funcionamiento de los sistemas informáticos de One Minetta o de
              terceros.
              <br />
              * Induzca, instigue o promueva acciones delictivas, ilícitas,
              disfuncionales o moralmente reprochables.
              <br />
              * Constituya manifestaciones agraviantes, denigratorias o
              difamatorias en perjuicio de terceros.
              <br />
              * Incluya o posibilite el acceso a material ilícito, prohibido por
              las leyes, riesgoso, ofensivo, nocivo, obsceno o, que de alguna
              otra forma resulte moralmente reprochable.
              <br />
              * Que estimule la realización de actividades que por su naturaleza
              sean consideradas inadecuadas, peligrosas o, riesgosas, para su
              ejecutor o para terceros.
              <br />
              * Incorporen alguna forma de publicidad o fin comercial no
              permitidos por One Minetta
              <br />
              * Que tienda a provocar un injustificado estado de conmoción en el
              o los receptores.
              <br />* Tenga por objeto recolectar información de los usuarios
              con el objeto de remitirles publicidad o propaganda de cualquier
              tipo o especie, sin que esta fuera expresamente solicitada.
            </p>
            <p>7.b Medidas de control</p>
            <p>
              Sin perjuicio de las acciones legales nacidas en cabeza de One
              Minetta o terceros, cuando el uso de los servicios o contenidos,
              llevado adelante por parte de un usuario, sea reputado por la
              Administración de One Minetta como USO PROHIBIDO, One Minetta
              podrá tomar todas las medidas que considere convenientes según su
              exclusivo criterio, pudiendo suspender o impedir el acceso a los
              servicios o contenidos a aquellos usuarios incursos en el uso
              prohibido de los mismos, y sin que para ello deba mediar
              comunicación previa alguna.
            </p>
            <ol>
              <li>
                <strong>Disponibilidad del sitio</strong>
              </li>
            </ol>
            <p>
              En correspondencia con la gratuidad de los servicios y contenidos
              brindados, One Minetta no garantiza la plena disponibilidad del
              sitio y el acceso a los servicios y contenidos del mismo.
            </p>
            <p>
              Los servicios y contenidos prestados por One Minetta no están
              destinados a su incorporación a ningún proceso productivo, o
              comercial de ninguna especie. Constituyen un elemento
              complementario, y queda al exclusivo arbitrio y responsabilidad
              del usuario basar algún tipo de actividad sobre la base de los
              mismos.
            </p>
            <p>
              One Minetta no garantiza el rigor científico de los servicios y
              contenidos incluidos en el sitio, no constituyen una herramienta
              profesional, One Minetta no asegura niveles de servicio ni la
              fiabilidad de los mismos.
            </p>
            <p>
              En ningún caso One Minetta responderá por la operatividad,
              eficacia y seguridad de los servicios y contenidos puestos a
              disposición de los usuarios; ni por los resultados negativos que
              puedan obtenerse como consecuencia de la implementación de los
              mismos en actividades de carácter lucrativo.
            </p>
            <ol>
              <li>
                <strong>Enlaces o Hipervínculos hacia miastralito.com</strong>
              </li>
            </ol>
            <p>
              El establecimiento de cualquier "hipervínculo" o enlace, entre un
              sitio o página web ajena al sitio “ http://miastralito.com/” y
              cualquier página de este último solo podrá realizarse bajo las
              siguientes condiciones:
            </p>
            <p>
              * No podrá constituir la reproducción total o parcial de ninguno
              de los servicios o contenidos de One Minetta, salvo cuando mediare
              autorización para ello, o cuando por la índole del servicio
              brindado, tal reproducción fuere inherente al mismo (Ej: Rss). *
              No se creará un browser, marco, ni un entorno o barra de
              navegación sobre las páginas web del sitio.
              <br />
              * No se incluirá ninguna manifestación falsa, inexacta o
              incorrecta sobre las páginas del sitio de One Minetta, y los
              servicios y contenidos allí incluidos.
              <br />
              * La página web desde la cual se establezca el hipervínculo,
              deberá en lo referente a su contenido y orientación respetar los
              lineamientos establecidos en estas Condiciones Generales de
              Registración, velando por el respeto a los derechos de terceros,
              al ordenamiento jurídico en su conjunto, y a la moral y las buenas
              costumbres.
              <br />* No podrá hacer uso de las marcas, denominaciones, ni demás
              signos distintivos propiedad de One Minetta, sin que medie previa
              autorización para ello.
            </p>
            <p>
              En ningún caso One Minetta será responsable por los contenidos o
              manifestaciones existentes en los sitios web desde donde se
              establezcan los hipervínculos hacia el sitio de One Minetta. El
              hecho que exista un hipervínculo entre una página web y el sitio
              de One Minetta no implica que One Minetta tenga conocimiento de
              ello o, que One Minetta mantenga relación alguna con los titulares
              del sitio web desde donde se establece el enlace.
            </p>
            <p>
              One Minetta, se reserva el derecho a solicitar la remoción o
              eliminación de cualquier enlace desde una página web ajena al
              sitio, en cualquier momento, sin expresión de causa, y sin que sea
              necesario preaviso alguno. El responsable de la página web desde
              la cual se efectuare el enlace tendrá un plazo de 48hs. contados a
              partir del pedido de One Minetta para proceder a la remoción o
              eliminación del mismo.
            </p>
            <ol>
              <li>
                <strong>
                  Enlaces o Hipervínculos desde http://miastralito.com/
                </strong>
              </li>
            </ol>
            <p>
              Los hipervínculos o enlaces existentes en el sitio de
              miastralito.com, que conectan con paginas o sitios web de
              terceros, tienen por finalidad mejorar la experiencia de
              navegación por el sitio de One Minetta del usuario, poniendo a su
              disposición herramientas, contenidos y servicios prestados por
              terceros ajenos a One Minetta.
            </p>
            <p>
              One Minetta no controla, no respalda ni garantiza la seguridad,
              calidad, licitud, falta de veracidad e idoneidad de los servicios
              y contenidos ofrecidos o brindados por terceros en el sitio, o a
              través del mismo cuando estos se encuentren fuera del sitio. En
              ese orden no responderá por los daños que pudieren experimentar
              los usuarios que adquirieren productos o utilizaren servicios de
              terceros.
            </p>
            <p>
              El acceso y utilización de páginas web enlazadas desde el sitio de
              One Minetta será exclusiva responsabilidad del usuario, quien
              deberá tomar todas las medidas de precaución necesarias de acuerdo
              al tipo de servicio contenido al que acceda, o producto que
              adquiera.
            </p>
            <p>
              El usuario que considere inadecuada una página vinculada desde el
              sitio, podrá elevar su queja o recomendación a través del
              mecanismo de denuncias puesto a disposición de los usuarios por
              parte de One Minetta.
            </p>
            <ol>
              <li>
                <strong>CONTRATO</strong>
              </li>
            </ol>
            <p>
              El documento del contrato entre el usuario y One Minetta está
              compuesto por las presentes condiciones de contratación, el
              formulario enviado por el usuario con sus datos personales y de
              pago, y los datos ubicados en One Minetta que completan el
              presente documento, prevaleciendo frente a cualquier otro acuerdo
              verbal o escrito previo o simultáneo.
            </p>
            <ol>
              <li>
                <strong>Legislación aplicable y jurisdicción.</strong>
              </li>
            </ol>
            <p>
              A todos los efectos legales en relación a los servicios y
              contenidos brindados o que puedan brindarse en el sitio, será
              aplicable la legislación vigente en la República Argentina, y será
              competente la Justicia Ordinaria de la Ciudad de Buenos Aires.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalData;
