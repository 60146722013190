import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga";
import Principal from "./principal";
import Leon from "./leon";
import Nubes2 from "./nubes2";
import Astrologia from "./astrologia";
import Signos from "./signos";
import Toro from "./toro";
import Nubes3 from "./nubes3";
import Libros from "./libros";
import RecorteNena from "./recorte-nena";
import NenaPajaro from "./nenapajaro";
import Nubes4 from "./nubes4";
import BookForm from "./form";
import Modal from "./modal";
import UserForm from "./userForm";
import { checkout } from "../services/checkoutService";
import { variables as vars } from "../utils/variables";
import $ from 'jquery'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Home = () => {
  const [bookData, setBookData] = useState({});
  const [userFormVisible, setUserFormVisible] = useState(false);
  const [userData, setUserData] = useState({});

  const userFormRef = useRef(null);

  useEffect(() => {
    ReactGA.initialize("UA-16376474-25");
  }, []);

  const gaEvent = (category, action, label = null) => {
    console.log("action?");
    const event = { category, action };
    if (label) event.label = label;
    ReactGA.event(event);
  };

  const updateBookData = (bookData) => {
    setBookData(bookData);
    setUserFormVisible(true);
    scrollToRef(userFormRef);
  };

  const updateUserData = async (userData) => {
    await setUserData(userData);
    //   console.log(userData)
    // sendData()
    const origBookData = bookData;
    if(userData.discountCode != "") {
      origBookData.discountCode = userData.discountCode
    }
    const birthDay = origBookData.birthDay;
    delete origBookData.birthDay;
    const sendData = {
      extra_data2: birthDay,
      extra_data1: JSON.stringify(origBookData),
      ...userData,
    };
    const { data } = await checkout(sendData);
    if (data.success) {
      if (vars("isInternational")) {
        window.userId = data.userId;
        console.log("window.userId", window.userId)
        const userCredentials = {
          email: userData.email,
          firstName: userData.name,
          lastName: userData.lastname,
        };
        userCredentials.addressLine1 = userData.address;
        userCredentials.city = userData.cityId;
        userCredentials.region = userData.provinceId;
        userCredentials.postalCode = userData.zipId;
        userCredentials.country = userData.countryId;
        userCredentials.phoneNumber = userData.phonePrefix + userData.phone;

        // console.log(userCredentials);
        $("#fs-btn").attr("data-fsc-item-path-value", data.fspath);
        // $('#fs-btn').click();
        window.fastspring.builder.add(
          $("#fs-btn").attr("data-fsc-item-path-value")
        );
        window.fastspring.builder.recognize(userCredentials);
        window.fastspring.builder.checkout();
      } else if (window.location.pathname == "/")
        window.location.assign(data.url);
    }
  };

  return (
    <React.Fragment>
      <Principal gaEvent={gaEvent} />
      <Leon />
      <Nubes2 />
      <Astrologia />
      <Signos />
      <Toro />
      <Nubes3 />
      <Libros />
      <RecorteNena />
      <NenaPajaro />
      <Nubes4 />
      <BookForm updateBookData={updateBookData} gaEvent={gaEvent} />
      <div ref={userFormRef}>
        {userFormVisible && (
          <UserForm updateUserData={updateUserData} gaEvent={gaEvent} />
        )}
      </div>

      <Modal />
    </React.Fragment>
  );
};

export default Home;
