import React from "react";

const NenaPajaro = () => {
  return (
    <section id="nena-pajaro">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 text-center"></div>
        </div>
        <div className="ei position-absolute">
          <div className="">
            <img
              className="titilar"
              src="/images/estrella-amarilla.svg"
              alt=""
            />
          </div>
          <img
            className="img-fluid"
            src="/images/estrellas-izquierda.svg"
            alt=""
          />
        </div>
        <div className="ed position-absolute">
          <img
            className="img-fluid"
            src="/images/estrellas-derecha.svg"
            alt=""
          />
          <img
            className="titilar2 e1"
            src="/images/estrella-amarilla.svg"
            alt=""
          />
          <img
            className="titilar2 e2"
            src="/images/estrella-amarilla.svg"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default NenaPajaro;
