import React from 'react';

const Nubes4 = () => {
    return ( 
		
	<section className="conten-nubes">
		<div className="nubes"><img src="/images/nubes.svg" alt="" /></div>
		<div className="ilust-nena"><img className="img-fluid" src="/images/nena-y-pajaro.png" alt="" /></div>
	</section>
     );
}
 
export default Nubes4;