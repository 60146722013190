import http from "./httpService";
import { configVariables as configVars } from "../utils/config";

export function getCountries(){
    return http.get(`${ configVars("seleccionesUrl") }/countries`)
}

export function getProvinces(){
    console.log(configVars("seleccionesUrl"))
    return http.get(`${ configVars("seleccionesUrl") }/provinces`)
}

export function getCities(provinceId){
    return http.get(`${ configVars("seleccionesUrl") }/localities?idTProvincia=${provinceId}`)
}

export function getZips(cityId){
    return http.get(`${ configVars("seleccionesUrl") }/zips?idTLocalidad=${cityId}`)
}

export function getLocation(){
    return http.get("https://get.geojs.io/v1/ip/country.json")
}