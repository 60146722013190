export const variables = {
    isInternational: true,
    countryId: null,

    price: 29.90,
    originalPrice: 29.90,
    deliveryPrice: 14.90,
    productId: "2010881U",
    campaignId: 10009,
    mktCodeId: "202012OSTJ",
    canalId: "2336e",
    payway: "fs",

    ourBooks: "A través de estos cuentos ilustrados, no solo conocerás de una forma didáctica esos rasgos de personalidad que acompañarán a los más pequeños durante toda su vida, sino que además te servirán para conocer y comunicarte mejor con ellos. Podrás potenciar sus fortalezas y trabajar sus debilidades a la vez que cultivas el amor por la lectura.",
    giftTitle: "Regala Mi Astralito",
    giftText: "Solo tienes que completar los datos para que armemos el perfil solar y el cuento personalizado de tu pequeño.",
    nameSmall: "(Tal como quieres que figure en la portada y en el cuento)",
    currency: "USD ",
    userFormTitle: "Completa tus datos personales",
    confirmEmail: "Confirma tu email",
    adress: "Domicilio (Donde quieres recibir el libro)",
    select: "Seleccionar",
    orderNow: "Encarga tu Astralito AHORA MISMO y recíbelo en tu domicilio.",
    visitOurWebsite:"Visita nuestro sitio web: ",
    needHelp:"¿Necesitas ayuda? ",
    contactUs: "Contáctate con nosotros",
    domicile: "Donde quieres recibir el libro",
    province: "Provincia/Estado/Región",
    city: "Localidad/Ciudad",
    phonePlaceholder: "",
    prefixPlaceholder: "Código de país con 00",
    shipMode: "DxG",
    shipping: "envío a todo el mundo."
    }