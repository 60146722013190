import React from 'react';

const Nubes3 = () => {
    return ( 
        
	<section className="conten-nubes">
		<div className="nubes"><img src="/images/nubes.svg" alt="" /></div>
	</section>
     );
}
 
export default Nubes3;