import React from "react";
import Joi from "joi-browser";
import swal from "sweetalert";
import moment from "moment";
import Form from "./common/form";
import getSign from "../services/signs";
import astralitoWebFavicon from "../assets/images/astralito_web_favicon.png";
import { variables as vars } from "../utils/variables";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

class BookForm extends Form {
  originalDedication =
    "Un cuento para encontrar respuestas sobre... (Máximo 50 caracteres)";

  state = {
    date: new Date(),
    birthDayFormat2: "",
    showCalendar: false,
    data: {
      name: "",
      birthDay: "",
      genre: "",
      dedication: "",
      sign: "",
    },
    sign: null,
    errors: {},
  };

  genres = { f: "femenino", m: "masculino" };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .max(15)
      .required()
      .error(() => {
        return {
          message: "El nombre es requerido.",
        };
      }),
    birthDay: Joi.string()
      .required()
      .error(() => {
        return {
          message: "La fecha es requerida.",
        };
      }),
    genre: Joi.string()
      .required()
      .error(() => {
        return {
          message: "El género es requerido.",
        };
      }),
    dedication: Joi.string().allow(""),
    sign: Joi.string().required(),
  };

  handleBirthdayChange = (e) => {
    // this.handleChange(e);
    let { sign, data } = this.state;
    const { value } = e.currentTarget;
    sign = getSign(value);
    if (!sign) return;
    data.sign = sign.name;
    this.setState({ data, sign });
    this.handleChange(e);
  };

  // resetDedication = (e) => {
  // 	console.log("focus")
  // 	const {data} = this.state
  // 	console.log(data.dedication === this.originalDedication)
  // 	if(data.dedication == this.originalDedication){
  // 		console.log("empty ded")
  // 		data.dedication = ""
  // 		this.setState({data})
  // 	}
  // }

  numberWithDots(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  doSubmit = async () => {
    this.props.gaEvent("comprar", "click");

    const { data, sign: signObj } = this.state;
    if (data.dedication === "") {
      data.dedication = "Un cuento para encontrar respuestas sobre...";
      this.setState({ data });
    }

    const { name, sign, genre, dedication } = data;

    const dt = moment(data.birthDay, "YYYY-MM-DD");
    const month = dt.month() + 1;
    const day = dt.date();
    const year = dt.year();

    const text = `Nombre: ${name}\n
		 Fecha de nacimiento: ${day}/${month}/${year}\n
		 Signo: ${sign} (del ${signObj.start.day}/${signObj.start.month} al ${signObj.end.day}/${signObj.end.month})\n
		 Género: ${this.genres[genre]}\n
		 Dedicatoria: ${dedication}`;

    swal({
      title: "¿Confirmás los datos ingresados?",
      text,
      icon: astralitoWebFavicon,
      buttons: ["Modificar", "Confirmar"],
      dangerMode: true,
    }).then((willConfirm) => {
      if (willConfirm) {
        this.props.gaEvent("confirmar", "click");
        this.props.updateBookData(this.state.data);
      }
    });
  };

  onCalendarChange = (date) => {
    this.setState({ date });

    let { sign, data } = this.state;

    let dateString = `${date}`;

    data.birthDay = dateString;
    var word = this.state.data.birthDay;
    let year = word.split(" ")[3];
    let month = word.split(" ")[1];
    let day = word.split(" ")[2];

    let monthNumber = new Date(Date.parse(month + " 1, 2012")).getMonth() + 1;

    let newDate = year + "-" + monthNumber + "-" + day;
    let dateFormat2 = day + "/" + monthNumber + "/" + year;
    this.setState({ birthDayFormat2: dateFormat2 });
    sign = getSign(newDate);
    if (!sign) return;
    data.sign = sign.name;

    data.birthDay = newDate;
    this.setState({ data, sign, showCalendar: false });
  };

  onFocusCalendarInput = () => {
    this.setState({showCalendar: true})
  };

  render() {
    const { data, errors } = this.state;
    return (
      <form id="form">
        <section id="compra">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-4">
                <h2 className="pt-4"> {vars("giftTitle")} </h2>
                <p>{vars("giftText")}</p>
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="nenita">
                      <img
                        className="img-fluid"
                        src="/images/nenitos.png"
                        alt=""
                      />
                      <h4 className="position-relative text-center">
                        A tan solo{" "}
                        {!vars("isInternational")
                          ? vars("currency") +
                            this.numberWithDots(vars("price"))
                          : vars("currency") + vars("price").toFixed(2)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-4">
                <div className="form-group mb-4">
                  <label htmlFor="nombre" className="m-0">
                    Nombre <br />
                    <small className="form-text text-muted mt-0 mb-2">
                      {vars("nameSmall")}
                    </small>
                  </label>
                  <input
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                    id="nombre"
                    maxLength="15"
                    name="name"
                    placeholder="Máximo 15 caracteres"
                    className={
                      "form-control" + (errors["name"] ? " is-invalid" : "")
                    }
                  />
                  {/* {<small className="form-text text-muted">* Máximo 15 caracteres</small>} */}
                  {errors["name"] && (
                    <div className="alert alert-danger">{errors["name"]}</div>
                  )}
                </div>
                <div className="form-group mb-4">
                  {/* <label htmlFor="nac">Fecha de Nacimiento (dd/mm/aaaa)</label> */}
                  <input
                    onFocus={this.onFocusCalendarInput}
                    value={this.state.birthDayFormat2}
                    placeholder="Fecha de Nacimiento (dd/mm/aaaa)"
                    className="form-control"
                    readonly
                  ></input>
                  {this.state.showCalendar && (
                    <div id="Calendario">
                      <Calendar
                        onChange={this.onCalendarChange}
                        value={this.state.date}
                        locale="es"
                      />
                      {errors["birthDay"] && (
                        <div className="alert alert-danger">
                          {errors["birthDay"]}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="genero">Género</label>
                  <div>
                    <div className="form-check form-check-inline mr-4">
                      <input
                        onChange={this.handleChange}
                        className={
                          "form-check-input" +
                          (errors["genre"] ? " is-invalid" : "")
                        }
                        type="radio"
                        id="genre-f"
                        name="genre"
                        value="f"
                      />
                      <label
                        className="form-check-label mr-3"
                        htmlFor="genre-f"
                      >
                        Femenino
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        onChange={this.handleChange}
                        className={
                          "form-check-input" +
                          (errors["genre"] ? " is-invalid" : "")
                        }
                        type="radio"
                        name="genre"
                        id="genre-m"
                        value="m"
                      />
                      <label
                        className="form-check-label mr-3"
                        htmlFor="genre-m"
                      >
                        Masculino
                      </label>
                    </div>
                    {errors["genre"] && (
                      <div className="alert alert-danger">
                        {errors["genre"]}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="dedicatoria" className="m-0">
                    Dedicatoria del libro:
                  </label>
                  <small className="form-text text-muted mt-0 mb-2">
                    (aparecerá en la portada del libro junto con el nombre)
                  </small>
                  <textarea
                    onChange={this.handleChange}
                    name="dedication"
                    className="form-control"
                    className={
                      "form-control" +
                      (errors["dedication"] ? " is-invalid" : "")
                    }
                    rows="3"
                    maxLength="50"
                    placeholder={this.originalDedication}
                  ></textarea>
                </div>
                <div className="mb-4 mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    );
  }
}

export default BookForm;
